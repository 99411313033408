.avatar_logo {
  align-items: center;
  display: flex; }

.avatar_icon {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 15px;
  height: 15px;
  margin: 0 6px 0 0;
  border: 1px solid #CCC;
  display: inline-block; }

.avatar_icon.primary {
  width: 30px;
  height: 30px;
  margin: 0 10px; }

body{
    font-family: 'Nunito', sans-serif;
    background-color: transparent;
}
h1, h2, h3, h4, h5 {
    font-family: 'Nunito', sans-serif;
}

.new-event.content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding-left: 0;
    padding-right: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 80px;
}

.q_wrapper {
  display: flex;
  flex-flow: row; }
  .q_wrapper .q_row_wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%; }
  .q_wrapper .q_row {
    display: flex;
    flex-flow: row;
    text-align: left; }
  .q_wrapper .degree1 {
    border-color: #0285F2;
    color: #0285F2; }
  .q_wrapper .degree2 {
    border-color: #9057a3;
    color: #9057a3; }
  .q_wrapper .degree3 {
    border-color: #EF7D02;
    color: #EF7D02; }
  .q_wrapper .degree4 {
    border-color: #EDCB02;
    color: #EDCB02; }
  .q_wrapper .q_label {
    font-size: 1em;
    padding: 5px 15px;
    margin-right: 20px;
    border-style: dotted;
    border-color: #0089ff;
    border-width: 0 1px 0 0;
    max-width: 200px;
    text-align: right; }
  .q_wrapper .q_row1 {
    margin-top: 10px;
    align-items: center; }
    .q_wrapper .q_row1 h3 {
      font-weight: 500;
      font-size: 1em;
      margin: 0.3em; }
  .q_wrapper .q_row2 {
    align-items: center;
    margin-bottom: 2px; }
    .q_wrapper .q_row2 h5 {
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      padding: 2px; }
    .q_wrapper .q_row2 h5, .q_wrapper .q_row2 span {
      display: inline-block;
      margin: 5px 5px 5px 0;
      font-size: 0.6em; }
  .q_wrapper .q_row3 {
    align-items: center; }
    .q_wrapper .q_row3 span, .q_wrapper .q_row3 h5, .q_wrapper .q_row3 h4 {
      margin: 0px 5px 0px 0;
      display: inline-block;
      font-size: 0.9rem; }
    .q_wrapper .q_row3 h5 {
      border-width: 2px;
      border-style: solid;
      border-radius: 10px;
      padding: 3px;
      font-weight: 600; }
    .q_wrapper .q_row3 h4 {
      font-weight: 600; }
    .q_wrapper .q_row3 i {
      font-size: 1.2em; }
  .q_wrapper .q_row4 h3 {
    font-size: 1.2em;
    margin: 0;
    font-weight: 300; }
  .q_wrapper .q_row5 {
    display: flex;
    align-items: center;
    font-size: 0.7em; }
    .q_wrapper .q_row5 span {
      margin: 0 10px; }
    .q_wrapper .q_row5 i {
      font-size: 1em; }
  .q_wrapper .bubble {
    position: absolute;
    border-radius: 20px 20px 20px 20px;
    background-color: white;
    padding: 10px 30px 20px 30px;
    border: 2px solid yellow; }
    .q_wrapper .bubble h6 {
      font-size: 14px;
      font-weight: 800; }
    .q_wrapper .bubble p {
      font-size: 13px; }
  .q_wrapper .bubble0_1 {
    width: 200px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble1_1 {
    width: 300px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble1_2 {
    width: 200px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble2_1 {
    width: 200px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble3_1 {
    width: 300px;
    border-top-left-radius: 0;
    opacity: 0; }
    .q_wrapper .bubble3_1 span {
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      font-size: inherit;
      padding: 0 5px;
      border-radius: 6px; }
  .q_wrapper .bubble3_2 {
    width: 250px;
    border-bottom-left-radius: 0;
    opacity: 0;
    bottom: 190px; }
  .q_wrapper .bubble4_1 {
    width: 300px;
    right: 50px;
    bottom: 140px;
    border-bottom-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble5_1 {
    width: 200px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble5_2 {
    width: 200px;
    border-bottom-left-radius: 0;
    bottom: 35px;
    opacity: 0; }
  .q_wrapper .bubble5_3 {
    width: 200px;
    border-top-left-radius: 0;
    opacity: 0; }
  .q_wrapper .bubble5_4 {
    width: 200px;
    border-bottom-left-radius: 0;
    bottom: 35px;
    opacity: 0; }
  .q_wrapper .readable {
    text-align: left;
    font-size: 0.9em;
    font-weight: 400; }
  .q_wrapper .graphic-box {
    display: flex;
    flex-flow: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 30px; }
    .q_wrapper .graphic-box .left {
      flex: 1;
      text-align: right;
      padding: 0 20px; }
    .q_wrapper .graphic-box .right {
      flex: 1;
      text-align: left;
      padding: 0 20px; }
  .q_wrapper .q_score {
    opacity: 0.5;
    font-size: 50px; }
  .q_wrapper .q_check {
    color: red; }
    .q_wrapper .q_check h4 {
      display: inline-block; }
  .q_wrapper .q_entity_icon {
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    margin: 0 10px -10px 0;
    border-radius: 50%;
    background-color: #2bbbff; }

.loggedOut {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ffffff 36%, #d3d3d3 81%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ffffff 36%, #d3d3d3 81%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ffffff 36%, #d3d3d3 81%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d3d3d3',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .loggedOut .screen {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5; }
  .loggedOut .postIt {
    background-color: #fff877;
    width: 150px;
    height: 150px;
    position: absolute;
    flex-flow: column;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 100px;
    left: 40px;
    transform: rotate(-20deg);
    box-shadow: -2px 0px 15px rgba(0, 0, 0, 0.3); }
    .loggedOut .postIt h4 {
      margin: 5px; }
      .loggedOut .postIt h4 span {
        opacity: 0.5;
        font-weight: 200; }

.filter_box {
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #CCC;
  padding: 20px 0 0 10px;
  font-size: 0.8em; }
  .filter_box h4 {
    font-size: 1.2em;
    font-weight: 200; }

.bp3-input:disabled, .bp3-input.bp3-disabled {
  border: 1px solid #b5b5b5 !important;
  background-color: white !important; }

.bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
  background: rgba(70, 99, 117, 0.5) !important; }

@media only screen and (max-width: 600px) {
  .postIt {
    background-color: greenyellow;
    top: 0;
    position: absolute; } }

@media only screen and (max-width: 600px) {
  .loggedOut .postIt {
    top: 78px;
    height: 100px;
    left: inherit;
    position: fixed;
    margin-left: 55%; } }

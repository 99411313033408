body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.dxmode .dx{
  margin: .25rem;
  border-style: dotted;
  border-color: #ffb700;
  border-width: 1px;
}

.dxtag{
  display: none;
}

.dxmode .dx .dxtag{
  background-color: rgba(0,0,0,.8);
  color: greenyellow;
  float: right;
  font-size: 0.6em;
  padding: 4px;
  display: block;
}

.dxmode .dx:before {
  content: "DM";
  padding:0 5px;
  background-color: rgba(0,0,0,.8);
  color: gold;
  float: right;
  font-size: 0.6em;
}
.Button__button___1FrBC {
  min-width: 80px !important;
}




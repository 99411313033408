.ccard_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 0; }
  .ccard_wrapper.full {
    flex-flow: column;
    align-items: flex-start; }
  .ccard_wrapper.full .ccard {
    border: 2px solid #FFF;
    border-radius: 10px;
    color: #CCC;
    width: 200px;
    height: 140px;
    transition: all 0.2s ease;
    box-shadow: 0 0 20px #d8d8d8;
    margin: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    padding: 20px;
    align-items: inherit; }
    .ccard_wrapper.full .ccard.active {
      border: 1px solid #FFF;
      color: #cecece; }
      .ccard_wrapper.full .ccard.active .last {
        color: white; }
    .ccard_wrapper.full .ccard .last {
      color: #003aff; }
    .ccard_wrapper.full .ccard .last::before {
      color: #CCC;
      content: "XXXX XXXX XXXX "; }
    .ccard_wrapper.full .ccard .cc_icon {
      width: 40px;
      border: 1px solid #CCC;
      border-radius: 5px; }
  .ccard_wrapper .ccard {
    border: 1px solid #003aff;
    color: #003aff;
    transition: all 1s ease;
    cursor: pointer;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .ccard_wrapper .ccard .cc_icon {
      width: 30px; }
    .ccard_wrapper .ccard.active {
      background-color: #003aff;
      color: white; }
    .ccard_wrapper .ccard .last {
      color: inherit;
      font-family: Courier, monospace;
      font-weight: 600; }
    .ccard_wrapper .ccard .last::before {
      content: " ";
      opacity: 0.8; }
  .ccard_wrapper .ccard:hover, .ccard_wrapper .full .ccard:hover {
    transform: scale(1.1, 1.1);
    transition: all 1s ease; }

.task_card {
  height: 50vh;
  min-height: 350px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 6% 6% 6% 6%;
  flex-flow: column; }
  .task_card h5 {
    margin: 20px 0 !important;
    color: #CCC !important;
    font-size: 1em !important; }
  .task_card .circle_coin {
    color: gold;
    border: 3px solid #ffeb3b;
    border-radius: 50%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 20px rgba(255, 220, 50, 0.5); }
  .task_card .pending {
    background-color: white;
    position: inherit;
    color: red;
    text-align: left;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin: 20px; }

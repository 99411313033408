.editor {
  background-color: #fff;
  height: 100vh;
  padding: 0;
  font-family: 'Nunito', sans-serif; }
  .editor .editor_sidebar {
    height: 100%;
    width: 18%;
    padding: 40px 0 0 13px;
    font-weight: 600;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: black;
    color: #FFF;
    position: fixed; }
    .editor .editor_sidebar a {
      color: #FFF !important; }
    .editor .editor_sidebar hr {
      opacity: 0.2; }
    .editor .editor_sidebar ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 10px;
      color: #c7c7c7; }
      .editor .editor_sidebar ul ul {
        padding-left: 10px;
        font-weight: 400;
        font-size: 11px; }
        .editor .editor_sidebar ul ul li:hover {
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 5px 0 0 5px; }
        .editor .editor_sidebar ul ul li.active:hover {
          border-radius: 5px 0 0 5px; }
      .editor .editor_sidebar ul li {
        padding: 5px; }
      .editor .editor_sidebar ul li.active {
        color: white !important;
        background-color: #0078ff;
        border-radius: 5px 0 0 5px;
        font-weight: 600; }
      .editor .editor_sidebar ul .menu_double {
        display: flex;
        flex-flow: column;
        align-items: left;
        padding: 0 0 0 0; }
        .editor .editor_sidebar ul .menu_double .menu_name {
          display: flex;
          flex-flow: row;
          justify-content: space-between; }
        .editor .editor_sidebar ul .menu_double ul {
          display: none; }
      .editor .editor_sidebar ul .menu_double.active {
        background-color: #0078ff;
        border-radius: 4px 0 0 4px;
        margin-right: -3px; }
        .editor .editor_sidebar ul .menu_double.active .menu_name {
          font-weight: 800; }
        .editor .editor_sidebar ul .menu_double.active ul {
          line-height: 0.7em;
          display: block;
          padding: 5px 0 10px 10px; }
    @media only screen and (max-width: 768px) {
      .editor .editor_sidebar {
        flex-flow: row;
        margin-bottom: 2px; }
        .editor .editor_sidebar ul li, .editor .editor_sidebar li:active {
          display: inherit;
          border-radius: 5px 0 0 5px !important;
          margin: 0 5px; } }
  .editor .actions_column {
    display: flex;
    justify-content: space-between;
    font-size: 0.5em;
    padding: 0 5px; }
    .editor .actions_column a, .editor .actions_column button {
      color: #0078ff !important;
      border: 1px solid #0078ff;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      margin: 0 !important; }
    .editor .actions_column a:hover {
      color: #03345c !important;
      border-color: #03345c !important; }
    .editor .actions_column.discrete a {
      color: #9d9d9d !important;
      border-color: #9d9d9d; }
    .editor .actions_column a.action_text {
      width: 100%;
      padding: 0 4px !important;
      border-radius: 20px;
      font-size: 1.4em;
      background-color: #0078ff;
      color: white !important; }
    .editor .actions_column a.action_text:hover {
      background-color: #FFF;
      color: #0078ff !important;
      border-color: #0078ff !important; }
  .editor .userbar {
    height: 85px;
    margin: 15px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 0.9rem;
    justify-content: space-between;
    color: #0078ff; }
    .editor .userbar .user_info {
      display: flex;
      flex-flow: column;
      align-items: center;
      color: #0078ff !important; }
      .editor .userbar .user_info h5 {
        color: inherit !important;
        margin: 10px 0; }
  .editor .result_wrapper {
    height: 100%; }
  .editor .result_header {
    padding: 15px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: #FFF;
    border-radius: 0 0 15px 15px; }
    .editor .result_header > h1 {
      display: flex;
      flex-flow: row;
      font-size: 0.9em;
      font-weight: 800;
      align-items: center;
      margin: 0; }
    .editor .result_header h2 {
      color: #01529d;
      font-size: 0.8rem;
      font-weight: 400; }
    .editor .result_header h4 {
      display: inline-block;
      font-weight: 400; }
    .editor .result_header span {
      padding: 0 2px;
      font-size: 0.9em; }
    .editor .result_header.new_header {
      background: #F9FFF3; }
      .editor .result_header.new_header h4 {
        color: #009a1f; }
    .editor .result_header.answer_header {
      background: #f8fcff; }
    .editor .result_header.edit_header {
      background: #FFFEF0; }
      .editor .result_header.edit_header h4 {
        color: #afb104; }
    .editor .result_header.review_header {
      background: #fff9ee; }
      .editor .result_header.review_header h4 {
        color: #e77700; }
    .editor .result_header.delete_header {
      background: #FFF9F9; }
      .editor .result_header.delete_header h4 {
        color: #ff2516; }
    .editor .result_header.neutral_header {
      border-radius: 0;
      padding-left: 0;
      border-style: dotted;
      border-width: 0 0 1px 0;
      border-color: #CCC; }
    .editor .result_header.expanded_header {
      border-radius: 0;
      padding: 30px 0 20px 0;
      border-color: #f1f1f1;
      border-style: dotted;
      border-width: 0 0 1px 0;
      margin-bottom: 30px; }
  .editor .result_filters {
    padding: 20px;
    background-color: #FAFAFA; }
  .editor .result_detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px; }
    .editor .result_detail h5 {
      color: #CCC;
      font-size: 0.9em; }
  .editor .truncate_id {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    resize: none;
    display: inline-block; }
  .editor .truncate_short {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    resize: none;
    display: inline-block; }
  .editor .workarea_wrapper {
    margin-top: 30px;
    text-align: left; }
  .editor .workarea {
    padding: 0;
    min-height: 75vh;
    flex: 1; }
  .editor .workarea_task {
    background-color: green;
    border: 1px solid #65dbb4;
    border-width: 4px 1px 1px 1px; }
  .editor .task_header {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #CCC;
    margin: 45px 0 20px 0;
    padding: 0 0 10px 0; }
  .editor .task_progress {
    margin-top: 50%; }
  .editor .task-tab {
    padding: 5px 20px !important;
    border-radius: 10px 0 0 10px !important;
    min-width: 120px !important;
    margin: 5px 0;
    color: #949494;
    font-size: 0.85em;
    border-color: #e6e6e6;
    border-style: solid;
    border-width: 1px 0 1px 1px; }
  .editor .task-tab[aria-selected="true"] {
    background-image: linear-gradient(to right, #f7f7f7, #FFF) !important;
    background-color: white !important;
    box-shadow: -4px 0 6px #e2e2e2 !important;
    font-weight: 600;
    border-width: 1px 0 1px 1px;
    border-color: #2196F3;
    color: #2196F3; }
  .editor .task_name {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.27px;
    align-items: center; }
    .editor .task_name .icon_over {
      color: #ffe873; }
    .editor .task_name .icon_down {
      color: #565656;
      margin: 0 20px 0 -24px; }
    .editor .task_name .assigned {
      padding: 0 0 0 20px;
      margin: 10px;
      border-style: solid;
      border-color: #CCC;
      border-width: 0 0 0 1px;
      color: #616161; }
  .editor .item_icon {
    margin-left: 10px; }
  .editor .icon1 {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 15px 0 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .editor img.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    box-shadow: 0 0 30px #0078fa; }
  .editor .editor_form {
    padding: 20px 20px !important; }
    .editor .editor_form table {
      border-collapse: collapse;
      width: 100%; }
    .editor .editor_form table td, .editor .editor_form table td * {
      vertical-align: top; }
    .editor .editor_form tr td {
      text-align: left;
      padding: 10px; }
    .editor .editor_form tr td:first-of-type {
      width: 17%;
      text-align: right;
      color: #adadad;
      font-weight: 100;
      font-size: 0.9em; }
    .editor .editor_form textarea {
      min-height: 80px; }
    .editor .editor_form h1, .editor .editor_form h2, .editor .editor_form h4, .editor .editor_form h5 {
      margin: 0; }
    .editor .editor_form h3 {
      color: #3f3f3f; }
    .editor .editor_form .tag {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      min-width: 20px;
      max-width: 100%;
      padding: 2px 6px;
      font-size: 12px;
      background-color: #e6e9ea;
      color: #2f5171;
      font-weight: 800;
      border-radius: 10px 0 0 10px !important;
      min-height: 30px;
      margin: 10px 0 10px 10px; }
      .editor .editor_form .tag.tag-alert {
        background-color: #fffa2b;
        color: #928a03;
        font-weight: 600;
        border-radius: 0 !important;
        box-shadow: 0 0 5px #b3b3b3;
        transform: rotate(-5deg) translateX(-5px) translateY(8px); }
        .editor .editor_form .tag.tag-alert svg, .editor .editor_form .tag.tag-alert i {
          margin-left: 5px; }
      .editor .editor_form .tag.tag-warning {
        background-color: #ffe2e2;
        color: #92132d;
        font-weight: 500; }
        .editor .editor_form .tag.tag-warning svg, .editor .editor_form .tag.tag-warning i {
          margin-left: 5px; }
      .editor .editor_form .tag.tag-warning:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        margin-right: -16px;
        border-left: 10px solid #ffe2e2; }
      .editor .editor_form .tag.mandatory:after {
        content: '*';
        color: #e0003b; }
      .editor .editor_form .tag.mandatory.tag-alert:after {
        content: '*';
        color: #e0003b; }
      .editor .editor_form .tag.label-id {
        border-radius: 20px;
        padding: 1px 5px;
        border: 1px solid #d2d2d2;
        color: #d2d2d2;
        font-size: 0.7em; }
    .editor .editor_form .field_details {
      color: #9e9e9e;
      font-size: 0.9em; }
    .editor .editor_form .instructions {
      font-size: 0.7em;
      color: grey;
      margin: 5px 0; }
    .editor .editor_form .editor_row {
      border-width: 0 0 1px 0;
      border-color: #CCC;
      border-style: dotted; }
    .editor .editor_form .alert_input {
      border: 1px solid red;
      border-radius: 6px;
      padding: 20px;
      color: red;
      background-color: white;
      display: flex;
      flex-flow: column;
      text-align: center; }
    .editor .editor_form .Form__formContainer___cu04J {
      margin: 0;
      text-align: left;
      width: fit-content; }
    .editor .editor_form .Form__formSection___3tqxz {
      margin: 0px;
      padding: 0px;
      display: inline; }
    .editor .editor_form .Section__sectionHeader___13iO4, .editor .editor_form .Section__sectionBody___3DCrX {
      display: none; }
    .editor .editor_form .editor_footer_wrapper {
      background-color: #f9f9f9;
      border-radius: 5px;
      padding: 20px;
      margin-top: 30px; }
    .editor .editor_form .editor_footer {
      display: flex;
      flex-flow: row;
      justify-content: space-between; }
      .editor .editor_form .editor_footer button {
        margin: 0 5px; }
    .editor .editor_form .signup_screen {
      z-index: 3;
      position: fixed;
      background-color: rgba(255, 0, 0, 0.5);
      width: 100%;
      height: 100vh;
      top: 0; }
  @media only screen and (max-width: 767px) {
    .editor .workarea_wrapper {
      margin-top: 50px; }
    .editor .task_progress {
      margin-top: 0;
      display: none; }
    .editor .bp3-tab-list {
      flex-direction: row !important;
      align-items: center;
      width: 100%;
      justify-content: center; }
      .editor .bp3-tab-list .task-tab {
        width: 100% !important;
        display: inline-block;
        min-width: inherit !important;
        border-width: 1px 1px 0 1px;
        border-radius: 8px 8px 0 0 !important; }
      .editor .bp3-tab-list .task-tab[aria-selected="true"] {
        background-image: linear-gradient(to bottom, #f7f7f7, #FFF) !important;
        box-shadow: 0 -4px 6px #e2e2e2 !important; }
    .editor .editor_form .tag.tag-alert {
      background-color: #fffa2b;
      color: #928a03;
      font-weight: 400;
      border-radius: 0 !important;
      margin: 0;
      box-shadow: none;
      transform: none; }
    .editor .icon1 {
      width: 25px;
      height: 25px; }
    .editor .result_header {
      margin: 10px 20px;
      border-radius: 6px;
      padding: 10px;
      flex-flow: row-reverse; }
      .editor .result_header h4 {
        font-size: 1.1em !important; }
    .editor .editor_footer_wrapper {
      width: 100vw;
      position: absolute; }
    .editor .editor_form {
      padding: 0 15px !important; }
      .editor .editor_form .editor_footer {
        width: 100%; }
        .editor .editor_form .editor_footer button {
          flex: 1 100%; }
      .editor .editor_form tr {
        padding-bottom: 20px;
        display: block; }
        .editor .editor_form tr td {
          display: block;
          width: 100%;
          padding: 10px 0; }
          .editor .editor_form tr td .bp3-select {
            width: 100% !important; }
        .editor .editor_form tr td:first-of-type {
          text-align: left;
          color: #adadad;
          display: block;
          width: 100%;
          padding: 0;
          font-size: 0.9em;
          font-weight: 600; }
    .editor .editor_sidebar {
      display: none;
      flex-flow: column;
      text-align: center; }
      .editor .editor_sidebar .userbar {
        height: 30px;
        margin: 0 0 20px 0; }
        .editor .editor_sidebar .userbar .user_info {
          flex-flow: row; }
          .editor .editor_sidebar .userbar .user_info h5 {
            margin: 0 10px 0; } }
  .editor .comments {
    background-color: #f9f9f9;
    padding: 5px 20px;
    border-radius: 5px; }
    .editor .comments .comment {
      padding: 0 10px;
      border-width: 0 0 1px 0;
      border-style: dotted;
      border-color: #ccc; }
      .editor .comments .comment h6 {
        margin: 15px 0; }
      .editor .comments .comment p {
        font-size: 0.9em;
        color: #2f2f2f;
        padding-left: 10px; }
    .editor .comments textarea {
      margin: 10px 0;
      width: 100%;
      border-radius: 3px;
      border-color: #CCC;
      padding: 10px; }
  .editor .ReactTable {
    border-width: 0;
    font-size: 0.9em; }
    .editor .ReactTable .rt-thead.-header {
      -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0);
      padding: 6px; }
    .editor .ReactTable .rt-thead .rt-resizable-header-content {
      text-align: left;
      font-size: 0.9em;
      color: #9e9e9e; }
    .editor .ReactTable .marked_highlight {
      background-color: #FFFDB8;
      padding: 3px; }
    .editor .ReactTable .marked_disabled {
      opacity: 0.2;
      cursor: not-allowed; }
  .editor .breadcrumb_header {
    margin: 60px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .editor .breadcrumb_header h2 {
      color: black;
      font-weight: 500;
      font-size: 2em;
      margin: 0; }
  .editor .note {
    margin: 15px 0;
    display: flex;
    align-items: center;
    color: orange; }
    .editor .note h4 {
      margin: 0 5px;
      display: inline-block;
      font-weight: 300; }
  .editor .settings-title {
    margin: 0;
    color: #0078ff; }

.task_details {
  display: flex;
  width: 90%;
  max-width: 400px;
  align-items: center;
  padding: 20px 0; }
  .task_details .dt {
    border-style: solid;
    border-color: #CCC;
    border-width: 0 1px 0 0;
    display: flex;
    padding: 0 10px; }

.welcome_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  flex-flow: column;
  padding-top: 50px;
  overflow-y: scroll; }
  .welcome_screen h1, .welcome_screen h2, .welcome_screen h3, .welcome_screen h4 {
    color: #dedede;
    font-weight: 800 !important;
    text-transform: uppercase;
    line-height: 0.8;
    margin: 5px; }
    .welcome_screen h1 span, .welcome_screen h2 span, .welcome_screen h3 span, .welcome_screen h4 span {
      display: inline-block;
      opacity: 0;
      transform: translateY(20px) rotate(90deg);
      transform-origin: left;
      animation: in 0.5s forwards; }
      .welcome_screen h1 span:nth-child(1), .welcome_screen h2 span:nth-child(1), .welcome_screen h3 span:nth-child(1), .welcome_screen h4 span:nth-child(1) {
        animation-delay: 0.1s; }
      .welcome_screen h1 span:nth-child(2), .welcome_screen h2 span:nth-child(2), .welcome_screen h3 span:nth-child(2), .welcome_screen h4 span:nth-child(2) {
        animation-delay: 0.2s; }
      .welcome_screen h1 span:nth-child(3), .welcome_screen h2 span:nth-child(3), .welcome_screen h3 span:nth-child(3), .welcome_screen h4 span:nth-child(3) {
        animation-delay: 0.3s; }
      .welcome_screen h1 span:nth-child(4), .welcome_screen h2 span:nth-child(4), .welcome_screen h3 span:nth-child(4), .welcome_screen h4 span:nth-child(4) {
        animation-delay: 0.4s; }
      .welcome_screen h1 span:nth-child(5), .welcome_screen h2 span:nth-child(5), .welcome_screen h3 span:nth-child(5), .welcome_screen h4 span:nth-child(5) {
        animation-delay: 0.5s; }
      .welcome_screen h1 span:nth-child(6), .welcome_screen h2 span:nth-child(6), .welcome_screen h3 span:nth-child(6), .welcome_screen h4 span:nth-child(6) {
        animation-delay: 0.6s; }
      .welcome_screen h1 span:nth-child(7), .welcome_screen h2 span:nth-child(7), .welcome_screen h3 span:nth-child(7), .welcome_screen h4 span:nth-child(7) {
        animation-delay: 0.7s; }
  .welcome_screen h1 {
    font-size: 60px;
    color: #a99fb3; }
  .welcome_screen h2 {
    font-size: 40px; }
  .welcome_screen h3 {
    font-size: 20px; }
  .welcome_screen h4 {
    margin: 20px 5px !important; }
  .welcome_screen button {
    min-width: 153px;
    display: inline-block;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    color: white;
    background-color: #694f85;
    border-color: #ccc;
    text-transform: uppercase;
    padding: 14px 0;
    letter-spacing: 1.1px;
    border: none; }
  .welcome_screen hr {
    margin: 30px 0;
    opacity: 0.3; }
  .welcome_screen textarea, .welcome_screen input, .welcome_screen select {
    background-color: transparent;
    box-shadow: -1px 1px #4d4d4d;
    border-radius: 0;
    margin-bottom: 30px; }
  .welcome_screen .label-form {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-top: 10px;
    text-align: left; }
  .welcome_screen .entity_select {
    width: 120px;
    height: 140px;
    border-radius: 5px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 5px;
    display: inline-block;
    color: #666;
    box-shadow: 0 0 10px #e4e4e4;
    background-color: white; }
    .welcome_screen .entity_select .logo {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: white;
      width: 100px;
      height: 100px;
      margin: 0 6px 0 0;
      display: inline-block;
      border-radius: 5px; }
  .welcome_screen .entity_select:hover {
    background-color: #e6e6e6; }

@keyframes in {
  0% {
    opacity: 0;
    transform: translateY(50px) rotate(90deg); }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0); } }

.signup_screen {
  background-color: white;
  color: #673AB7;
  z-index: 20;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: fixed;
  width: 100%; }

.speech-bubble {
  position: relative;
  background: #FFF;
  border-radius: .4em;
  box-shadow: 0 0 30px #CCC;
  min-width: 110px;
  margin-top: 100px;
  color: #a998ca;
  font-weight: 600;
  font-size: 0.9em;
  padding: 10px 20px; }

.speech-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #FFF;
  border-bottom: 0;
  border-right: 0;
  margin-left: -10px;
  margin-bottom: -20px; }

@media only screen and (max-width: 600px) {
  .welcome_screen {
    padding-top: 10px; }
  .task_details {
    flex-flow: column;
    align-items: flex-start; }
    .task_details .dt {
      border-width: 0;
      padding: 5px 0; }
  .speech-bubble {
    width: 30%;
    margin: 0;
    top: 420px;
    right: 0;
    padding: 10px;
    position: absolute;
    text-align: right;
    border-radius: 6px 0 0 6px; }
  .speech-bubble:after {
    display: none; } }

.react-datepicker-popper {
  z-index: 10 !important; }

.Stepper {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }

.Stepper__step {
  position: relative;
  display: table-cell;
  text-align: left;
  padding: 0.5rem; }
  .Stepper__step.is-complete .Stepper__info {
    border-color: #99aeb8;
    background-color: #99aeb8; }
  .Stepper__step.is-complete .Stepper__label {
    color: #99aeb8; }
  .Stepper__step.is-active .Stepper__info {
    border-color: #607D8B;
    background-color: #607D8B; }
  .Stepper__step.is-active .Stepper__label {
    color: #607D8B; }
  .Stepper__step.is-warning .Stepper__info {
    border-color: #f1c40f;
    background-color: #f1c40f; }
  .Stepper__step.is-warning .Stepper__label {
    color: #f1c40f; }
  .Stepper__step.is-error .Stepper__info {
    border-color: #e95a4b;
    background-color: #e95a4b; }
  .Stepper__step.is-error .Stepper__label {
    color: #e95a4b; }

.Stepper__indicator {
  position: relative;
  display: block;
  z-index: 2; }

.Stepper__label {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: #cfd7de;
  z-index: 2; }

.Stepper__info {
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #e3e8ec;
  border-radius: 50%;
  background-color: #e3e8ec;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #fff;
  z-index: 2; }

.Stepper__panel {
  display: none; }

.Stepper .Stepper__step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 1.5rem;
  width: 50%;
  height: 0.125rem;
  background-color: #e3e8ec;
  z-index: 1; }

.Stepper .Stepper__step:last-child:after {
  display: none; }

.Stepper--inline .Stepper__indicator,
.Stepper--inline .Stepper__label {
  display: inline-block;
  vertical-align: inherit; }

.Stepper--inline .Stepper__label {
  text-align: left;
  padding: 0 0.5rem;
  background: #fff; }

.Stepper--inline.Stepper--bottom .Stepper__label {
  vertical-align: middle; }

.Stepper--inline.Stepper--bottom .Stepper__step:after {
  top: auto;
  bottom: 1.75rem; }

.Stepper--vertical .Stepper__step {
  display: block;
  text-align: left; }

.Stepper--vertical .Stepper__label {
  padding-left: 1rem; }

.Stepper--vertical .Stepper__indicator,
.Stepper--vertical .Stepper__label {
  display: table-cell; }

.Stepper--vertical .Stepper__panel {
  margin-left: 3.5rem; }

.Stepper--vertical .Stepper__step:after {
  content: " ";
  position: absolute;
  left: 1.75rem;
  top: 2.5rem;
  bottom: -0.5rem;
  width: 0;
  height: auto;
  border-left: 0.125rem solid #e3e8ec; }

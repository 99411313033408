@charset "UTF-8";
.theme_wrapper.dark_orange {
  font-family: 'Nunito', sans-serif;
  color: white !important;
  /*ENTITY SCORE*/
  /*ENTITY TABS*/ }
  .theme_wrapper.dark_orange .highlight_color {
    color: #f06827 !important; }
  .theme_wrapper.dark_orange table {
    color: inherit !important; }
    .theme_wrapper.dark_orange table td {
      color: inherit !important; }
  .theme_wrapper.dark_orange a {
    color: #ffffff !important;
    text-decoration: none !important; }
  .theme_wrapper.dark_orange a:hover {
    color: #f38957 !important;
    text-decoration: none !important; }
  .theme_wrapper.dark_orange a.button {
    color: #ffffff !important;
    text-decoration: none !important;
    border: 1px solid #f06827;
    background-color: transparent;
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 0.9em;
    opacity: 0.8; }
  .theme_wrapper.dark_orange a.button:hover {
    color: white !important;
    text-decoration: none !important;
    opacity: 1; }
  .theme_wrapper.dark_orange a.primary {
    color: white !important;
    background-color: #f06827;
    text-decoration: none !important;
    border: 1px solid #f06827;
    padding: 8px 10px !important;
    border-radius: 4px;
    font-size: 14px !important;
    opacity: 0.9; }
  .theme_wrapper.dark_orange a.primary:hover {
    color: white !important;
    background-color: #a63d0b !important;
    text-decoration: none !important;
    opacity: 1; }
  .theme_wrapper.dark_orange a.primary.disabled {
    color: #ffffff !important;
    background-color: #CCC;
    border-width: 0;
    opacity: 0.5;
    cursor: not-allowed; }
  .theme_wrapper.dark_orange a.primary.disabled:hover {
    background-color: #CCC !important; }
  .theme_wrapper.dark_orange a.primary.disabled:hover::before {
    position: absolute;
    transform: translate(-40px, -40px);
    display: block;
    width: 200px;
    content: '⇦ please write a question first'; }
  .theme_wrapper.dark_orange ::selection {
    color: white;
    background: #f06827;
    opacity: 1; }
  .theme_wrapper.dark_orange .entity_head {
    padding-top: 40px; }
    .theme_wrapper.dark_orange .entity_head .entity_info {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      height: 100px;
      text-align: left; }
  .theme_wrapper.dark_orange .entity_mosaic_grid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; }
    .theme_wrapper.dark_orange .entity_mosaic_grid .entity_mosaic {
      display: flex;
      flex-flow: column;
      width: 100px;
      max-width: 100px;
      margin: 3px;
      justify-content: flex-start;
      align-items: center;
      text-align: center; }
    .theme_wrapper.dark_orange .entity_mosaic_grid img {
      width: 80px;
      height: 80px;
      border-radius: 5px; }
    .theme_wrapper.dark_orange .entity_mosaic_grid p {
      color: grey;
      font-size: 0.8em;
      line-height: 1;
      margin: 15px 0 20px 0; }
  .theme_wrapper.dark_orange .entity_background {
    background-size: cover;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1; }
  .theme_wrapper.dark_orange .entity_background:before {
    background-color: black;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.8; }
  .theme_wrapper.dark_orange .dashboard_card {
    background-color: transparent;
    border-width: 0; }
    .theme_wrapper.dark_orange .dashboard_card h3 {
      text-align: left;
      font-size: 5em;
      color: white;
      line-height: 0.9;
      font-weight: 400;
      margin: 0; }
    .theme_wrapper.dark_orange .dashboard_card h4 {
      margin: 3em 0 1em 0 !important;
      color: white;
      font-size: 1.3em; }
    .theme_wrapper.dark_orange .dashboard_card h5 {
      margin: 0;
      font-size: 1.9em;
      color: white;
      font-weight: 400; }
  .theme_wrapper.dark_orange input {
    padding: 20px 40px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px 0 white;
    border-radius: 3px 3px 0 0;
    font-size: 1.2em;
    font-weight: 400;
    width: 100%;
    outline: none;
    border: none;
    height: 30px;
    vertical-align: middle;
    line-height: 30px;
    color: #f38957; }
  .theme_wrapper.dark_orange input:focus {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 2px 0 #f06827; }
  .theme_wrapper.dark_orange .bp3-icon-search {
    color: #f06827;
    opacity: 0.6; }
  .theme_wrapper.dark_orange input::placeholder {
    color: white;
    opacity: 0.3;
    /* Firefox */ }
  .theme_wrapper.dark_orange .search_filters {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.8em; }
    .theme_wrapper.dark_orange .search_filters a {
      padding: 0 5px;
      font-size: 1em; }
    .theme_wrapper.dark_orange .search_filters a.selected {
      color: #f06827 !important;
      text-decoration: none !important;
      opacity: 1;
      font-weight: 600;
      font-size: 1.1em; }
    .theme_wrapper.dark_orange .search_filters a.selected:hover {
      color: white !important;
      text-decoration: none !important; }
    .theme_wrapper.dark_orange .search_filters .search_results {
      font-size: 1em;
      opacity: 0.5; }
  .theme_wrapper.dark_orange .embed_box {
    width: 100%;
    min-width: 200px;
    background-color: transparent;
    color: #f06827;
    padding: 20px;
    border: 1px dotted #f06827;
    border-radius: 5px;
    font-size: 0.95em;
    margin-bottom: 10px; }
  .theme_wrapper.dark_orange .faq_answer .faq_answer_row1 {
    padding: 10px 0;
    font-weight: 600;
    display: flex;
    flex-flow: row;
    justify-content: space-between; }
  .theme_wrapper.dark_orange .faq_answer .faq_answer_row2 {
    font-size: 1.1em;
    font-weight: 400;
    padding: 10px 0 30px 0;
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: rgba(255, 255, 255, 0.4); }
  .theme_wrapper.dark_orange .float_box {
    color: #323334; }
    .theme_wrapper.dark_orange .float_box .float_score {
      position: relative;
      top: 75px; }
      .theme_wrapper.dark_orange .float_box .float_score h5 {
        color: white;
        font-size: 1.3em;
        margin-top: 20px; }
      .theme_wrapper.dark_orange .float_box .float_score h2 {
        color: white; }
  .theme_wrapper.dark_orange .entity_info {
    display: flex;
    align-items: center; }
    .theme_wrapper.dark_orange .entity_info img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      margin-right: 15px; }
    .theme_wrapper.dark_orange .entity_info h2 {
      font-weight: 100; }
  .theme_wrapper.dark_orange .entity_score {
    padding: 15px 15px 0 0; }
    .theme_wrapper.dark_orange .entity_score .score {
      border: 1px solid #CCC;
      color: #CCC;
      padding: 20px;
      font-size: 2em;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center; }
  .theme_wrapper.dark_orange .entity_tabs {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 90%; }
    .theme_wrapper.dark_orange .entity_tabs .tabs {
      border-radius: 0 9px 9px 0;
      max-width: 200px;
      padding: 25px 20px;
      margin: 3px 0;
      font-size: 0.8em;
      color: white;
      display: flex;
      height: 30px;
      justify-content: center;
      align-items: center; }
      .theme_wrapper.dark_orange .entity_tabs .tabs i {
        font-size: 1.3em; }
    .theme_wrapper.dark_orange .entity_tabs .tabs:hover {
      color: #f06827;
      background-color: rgba(50, 50, 50, 0.3); }
    .theme_wrapper.dark_orange .entity_tabs .tabs.active {
      color: #f06827;
      font-weight: 600; }
  .theme_wrapper.dark_orange .entity_leftbar {
    position: fixed;
    left: 0;
    top: 60px;
    height: 100%; }
  .theme_wrapper.dark_orange .entity_rightbar {
    position: absolute;
    top: 50px;
    display: flex;
    flex-flow: column; }
    .theme_wrapper.dark_orange .entity_rightbar .join {
      position: fixed;
      top: 70px;
      right: 20px; }
  .theme_wrapper.dark_orange .entity_action_right {
    float: right; }
  .theme_wrapper.dark_orange .entity_settings {
    padding-top: 35px;
    text-align: left; }
    .theme_wrapper.dark_orange .entity_settings .settings_header {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      font-size: 1em;
      color: #bfbfbf; }
    .theme_wrapper.dark_orange .entity_settings .settings_social {
      display: flex;
      justify-content: space-between;
      min-width: 250px;
      width: 50%;
      margin-bottom: 40px; }
  .theme_wrapper.dark_orange .answer_table tr > td:first-child {
    opacity: 0.5; }
  .theme_wrapper.dark_orange .answer_table td {
    vertical-align: initial; }
  .theme_wrapper.dark_orange .answer_table h1 {
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 2em; }
  .theme_wrapper.dark_orange .answer_table h2 {
    font-weight: 300;
    font-size: 1.5em;
    padding-bottom: 10px; }
  .theme_wrapper.dark_orange .question_list {
    font-size: 1em;
    padding: 1px 0 5px 0; }
  .theme_wrapper.dark_orange .corner {
    width: 10em;
    height: 6em;
    position: fixed;
    top: 0;
    right: 0; }
    .theme_wrapper.dark_orange .corner .bp3-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      color: white;
      z-index: 2; }
  .theme_wrapper.dark_orange .corner::before,
  .theme_wrapper.dark_orange .corner::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid; }
  .theme_wrapper.dark_orange .corner::before {
    border-width: 1.5em;
    border-right-color: #d54f0f;
    border-top-color: #d54f0f; }
  .theme_wrapper.dark_orange .corner::after {
    border-radius: 0;
    border-width: 2.35em;
    border-right-color: #f06827;
    border-top-color: #f06827; }
  @media only screen and (max-width: 767px) {
    .theme_wrapper.dark_orange .entity_tabs {
      flex-flow: row;
      height: inherit;
      justify-content: center; }
      .theme_wrapper.dark_orange .entity_tabs > div {
        display: flex; }
    .theme_wrapper.dark_orange .entity_leftbar {
      height: inherit; }
    .theme_wrapper.dark_orange .entity_mosaic {
      width: 80px !important;
      max-width: 80px !important; }
      .theme_wrapper.dark_orange .entity_mosaic p {
        font-size: 0.7em !important; }
    .theme_wrapper.dark_orange img {
      width: 70px !important;
      height: 70px !important; }
    .theme_wrapper.dark_orange .entity_leftbar {
      background-color: black;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 20;
      position: fixed;
      top: 0; }
    .theme_wrapper.dark_orange .entity_rightbar {
      display: none; }
    .theme_wrapper.dark_orange .entity_action_right, .theme_wrapper.dark_orange .entity_action_left {
      width: 90vh;
      float: none;
      display: table-cell;
      text-align: center; }
    .theme_wrapper.dark_orange .entity_head {
      padding-top: 80px; }
    .theme_wrapper.dark_orange .answer_table {
      margin-top: 40px; }
      .theme_wrapper.dark_orange .answer_table td {
        display: table-row; } }
  .theme_wrapper.dark_orange .betanote {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #efeddc;
    border-radius: 7px;
    margin: 5px;
    width: 150px;
    min-width: 150px;
    padding: 10px 20px;
    color: #56480c;
    font-size: 0.8em;
    /* padding: 0 10px; */
    float: right;
    opacity: 0.7;
    line-height: 0.9; }
  .theme_wrapper.dark_orange .bp3-tab[aria-selected="true"] {
    border-radius: 0;
    -webkit-box-shadow: inset 0 -3px 0 #f06827;
    box-shadow: inset 0 -3px 0 #f06827; }
